import s from "./Main.module.css"
import {NavLink} from "react-router-dom";


const ResetPasswordConfirm = () => {

    return (
        <div className={s.main}>
            <div className={s.loginForm}>
                <h1>Восстановление доступа</h1>
                <div className={s.loginInfo} style={{textAlign: "center"}}>
                    <label>
                        Пароль был изменен
                    </label>
                    <label>
                        <NavLink to="/login">Перейти на страницу авторизации</NavLink>
                    </label>
                </div>
            </div>
        </div>
    )
}
export default ResetPasswordConfirm

