import m from "../MainPages/Main.module.css";
import s from "./CourseEx.module.css";
import {CourseDescription, PageTitle, RazdelPodrazdel, SubTitle, Zadanie} from "../Modules/Modules";
import {TeacherBlock} from "./CourseExample1";
import {NavLink} from "react-router-dom";

const CoursePartExample = () => {
    return (
        <div className={m.main}>
            <div className={s.coursePage}>
                <div className={s.courseLeft}>
                    <SubTitle title="Опека и попечительство"/>
                    <PageTitle
                        title="Споры родителей, связанные  с воспитанием детей. Особенности рассмотрения споров, связанных с воспитанием детей, с участием иностранного элемента"/>
                    <TeacherBlock/>
                    <p></p>
                    <RazdelPodrazdel title="Раздел 1. / Подраздел 1.1">
                    </RazdelPodrazdel>
                    <CourseDescription title="Практическое занятие">
                        <Zadanie questions={questions}/>
                    </CourseDescription>
                    <div className={s.send}>
                        <NavLink to="/">Следующий раздел</NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CoursePartExample

const questions = [
    {
        id: "1",
        question: "В случае, если на момент судебного разбирательства ребенок проживает в одном жилом помещении с обоими родителями, при этом предполагается, что оба родителя будут проживать в других жилых помещениях (например, после размена данного жилого помещения), либо один из родителей останется в занимаемом семьей в настоящее время жилом помещении, а второй переедет в другое жилое помещение, в каком жилом помещении органу опеки и попечительства целесообразнее проводить обследование условий жизни ребенка и обоих родителей, претендующих на совместное проживание с ребенком?*",
    },
    {
        id: "2",
        question: "Имеется ли утвержденная форма акта обследования условий жизни ребенка и лица (лиц), претендующего на его воспитание? Введите свой ответ:*",
        answer: "Имеется",
    },
    {
        id: "3",
        question: "Что должен содержать в акте обследования условий жизни ребенка и лица (лиц), претендующего на его воспитание? Введите свой ответ:*",
        answer: "Имеется",
    },
    {
        id: "4",
        question: "Какие выводы органа опеки и попечительства должны быть в акт обследования условий жизни ребенка и лица (лиц), претендующего на его воспитание? Введите свой ответ:*",
    },
    {
        id: "5",
        question: "В каких постановлениях Пленума Верховного Суда Российской Федерации содержится правовая позиция Верховного Суда Российской Федерации относительно урегулирования споров, связанных с воспитанием детей, в судебном порядке? Введите свой ответ:*",
    },
]