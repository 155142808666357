import s from "../Modules/Modules.module.css";
import m from "./CourseEx.module.css"
import {CourseDescription, CourseFilter, PageTitle, SubTitle} from "../Modules/Modules";
import {TeacherBlock} from "./CourseExample1";
import pdp from "../../assets/Imgs/pdp.jpg";
import {findCommonElement} from "../../App";
import {useSelector} from "react-redux";
import {checkGroup} from "./OpecaCourse1";
import {NavLink} from "react-router-dom";

const PdpCourse2 = () => {
    const user = useSelector((state) => state.user);
    const groupsId = [10]



    return (
        <div className={m.main}>
            <div className={m.coursePage}>
                <div className={m.courseLeft}>
                    <SubTitle title="Профилактика девиантного поведения"/>
                    <PageTitle
                        title="Психологические проблемы детско-подросткового возраста: профилактика и коррекция в общеобразовательных организациях"/>
                    <TeacherBlock name="Артамонова Елена Геннадьевна"
                                  position='Заместитель директора по развитию системы профилактики девиантного поведения ФГБУ "Центр защиты прав и интересов детей", кандидат психологических наук'/>
                    {(window.innerWidth < 976 && (checkGroup(user, groupsId) !== true || user.isAdmin !== true || user.isStaff !== true)) ?
                        <div className={m.courseRight}>
                            <p></p>
                            <BuyWindow/>
                        </div> : <></>
                    }
                    {(window.innerWidth < 976 && (checkGroup(user, groupsId) === true || user.isAdmin === true || user.isStaff === true)) ?
                        <div className={m.courseRight}>
                            <p></p>
                            <CourseFilter filterArray={PDP2Array}/>
                        </div> : <></>
                    }
                    <div className={m.courseImg}>
                        <img src={pdp} alt=""/>
                    </div>
                    <CourseDescription title="Описание">
                        <CourseDesc/>
                    </CourseDescription>
                    {/*<CourseDescription title="Раздел 1.">*/}
                    {/*    <Razdel/>*/}
                    {/*</CourseDescription>*/}
                    {/*<CourseDescription title="Раздел 2.">*/}
                    {/*</CourseDescription>*/}
                    {/*<CourseDescription title="Календарь">*/}
                    {/*    <CalendarExample/>*/}
                    {/*</CourseDescription>*/}
                </div>
                <div className={m.courseRight}>
                    {
                        (window.innerWidth >= 976 && (checkGroup(user, groupsId) !== true || user.isAdmin !== true || user.isStaff !== true)) ?
                            <BuyWindow/>
                            : <></>
                    }
                    {
                        (window.innerWidth >= 976 && (checkGroup(user, groupsId) === true || user.isAdmin === true || user.isStaff === true)) ?
                            <CourseFilter filterArray={PDP2Array}/>
                            : <></>
                    }

                </div>

            </div>
        </div>
    )
}
export default PdpCourse2

const CourseDesc = () => {
    return <div className={s.courseDescription}>
        Дополнительная профессиональная программа повышения квалификации (далее – программа) направлена на рассмотрение
        вопросов:
        <ul>
            <li>
                индивидуально-возрастные особенности детей и подростков;
            </li>
            <li>
                социально-психологические факторы девиаций в подростковом возрасте;
            </li>
            <li>
                диагностика и коррекция психологических проблем обучающихся общеобразовательных организаций;
            </li>
            <li>
                профилактические программы общеобразовательных организаций.
            </li>
        </ul>
        Программа предназначена для повышения квалификации педагогических работников общеобразовательных организаций:
        педагогов, педагогов-психологов, специалистов сферы профилактики.
        <p></p>
        <strong>Цель программы:</strong><br style={{fontSize: "24px"}}/>
        Целью программы является предоставление знаний о социально-психологических особенностях детей и подростков,
        механизмах формирования распространенных нарушений в поведении (агрессия, проблемы коммуникации и саморегуляции,
        зависимое поведение, самоповреждение, суицидальные риски и др.), их профилактике, диагностике и коррекции в
        общеобразовательных организациях.
        <p></p>
        <strong>Программа курса:</strong><br style={{fontSize: "24px"}}/>
        <ol>
            <li>
                Социально-психологические особенности подросткового возраста.
            </li>
            <li>
                Распространенные нарушения в поведении детей и подростков.
            </li>
            <li>
                Основы диагностики и оказания психолого-педагогической помощи в общеобразовательной организации.
            </li>
            <li>
                Модели профилактики девиантного поведения.
            </li>
        </ol>
        <p></p>
        <strong>Преподаватели (планируемые):</strong><br style={{fontSize: "24px"}}/>
        <ul>
            <li>
                <b>Артамонова Елена Геннадьевна</b>, заместитель директора по развитию системы профилактики девиантного
                поведения ФГБУ "Центр защиты прав и интересов детей", кандидат психологических наук;
            </li>
            <li>
                <b>Дубровский Роман Геннадьевич</b>, аналитик II категории ФГБУ "Центр защиты прав и интересов детей",
                кандидат социологических наук;
            </li>
            <li>
                <b>Бочкова Виолетта Владимировна</b>, аналитик II категории ФГБУ "Центр защиты прав и интересов детей".
            </li>
        </ul>
        <p></p>
        <strong>Характеристики курса</strong><br style={{fontSize: "24px"}}/>
        Трудоемкость программы: 20 ак. часов <br/>
        Длительность (недель): 3<br/>
        Учебная нагрузка по курсу: до 2 часов в день<br/>
        Язык курса: русский<br/>
        Удостоверение установленного образца: да.
        <p></p>
        <strong>Планируемый период обучения: с 20 мая по 14 июня 2024 года</strong><br style={{fontSize: "24px"}}/>
        Планируемое время проведения занятий: с 14:00 до 16:00<br/>
        Последнее занятие с 14:00 до 18:00
    </div>
}

// const Razdel = () => {
//     return <div className={s.courseDescription}>
//         <strong>Правовые основы и направления деятельности органов опеки и попечительства при рассмотрении споров о
//             воспитании
//             детей</strong>
//         <p></p>
//         <div className={s.ref}>
//             <a href="/course1/part1"><strong>Подраздел 1.1</strong><br/>
//                 Правовые основания и направления деятельности органов опеки и попечительства при рассмотрении споров о
//                 воспитании детей (на досудебной стадии, в судебном процессе, в исполнительном производстве)</a>
//         </div>
//         <p></p>
//         <strong>Подраздел 1.2</strong><br/>
//         Учет мнения ребенка при решении вопросов, затрагивающих его права и интересы. Методы и способы выяснения мнения
//         ребенка в случаях, предусмотренных законодательством. Учет психологических, медицинских, социальных факторов при
//         общении с детьми разных возрастных групп при выявлении их мнения
//     </div>
// }
//
// const Zadanie = ({
//                      questions
//                  }) => {
//     return (
//         <div className={s.courseDescription}>
//             {questions.map(el =>
//                 <Question id={el.id} question={el.question} answer={el.answer}/>
//             )}
//             <div className={s.send}>
//                 <a href="/">Отправить ответы</a>
//             </div>
//
//         </div>
//     )
// }
//
// const Question = ({
//                       id, question, answer = null
//                   }) => {
//     return (
//         <div>
//             <div style={{paddingBottom: "20px"}}>
//                 <strong>Вопрос {id}</strong>
//             </div>
//             <div style={{paddingBottom: "20px"}}>
//                 {question}
//             </div>
//             <div className={s.qInput}>
//                 <input style={answer === null ? {backgroundColor: "white"} : {backgroundColor: "#008E4C17"}}
//                        id="questionInput" type="text" placeholder={answer === null ? "Ответ" : answer}/>
//                 <button id="searchButton"
//                         style={answer === null ? {backgroundColor: "#e07b9b"} : {backgroundColor: "#3F5070"}}>{answer === null ? "Ответить" : "Ответ принят"}</button>
//             </div>
//
//         </div>
//     )
// }

const BuyWindow = () => {
    return (
        <div className={m.buy}>
            <div className={m.info}>
                <div style={{fontSize: "18px", paddingBottom: "5px"}}>
                    <strong>Принять участие</strong>
                </div>
                {/*<div style={{fontSize: "16px", paddingBottom: "10px"}}>*/}
                {/*    <span><strong>0 человек</strong></span> прошли курс*/}
                {/*</div>*/}
                <div style={{fontSize: "12px", paddingBottom: "30px", color: "#808080"}}>
                    20 академических часов
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "10px"
                }}>
                    <div style={{fontSize: "12px", color: "#808080"}}>
                        Стоимость курса
                    </div>
                    <div style={{fontSize: "18px"}}>
                        <strong>10 000 ₽</strong>
                    </div>
                </div>
                <div className={m.buyButton}>
                    <NavLink to="#">Записаться на курс</NavLink>
                </div>
            </div>
        </div>
    )
}

export const PDP2Array = [
    {
        title: "Описание курса",
        url: "/pdpcourse2",
    },
    {
        title: "Информация для обучающихся",
        url: "/pdpcourse2/info"
    },
]
