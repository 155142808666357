import s from "./NewLogin.module.css"
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, ConfigProvider, Form, Input} from "antd";
import {HeaderThemes} from "../../themes/HeaderThemes";
import {register} from "../../Redux/auth/authActions";
import {useNavigate} from "react-router-dom";


const AccRegistration = () => {
    const {registerError, isRegistered} = useSelector(state => state.auth);
    const dispatch = useDispatch();
    let formdata = new FormData();
   const navigate = useNavigate()

    if (isRegistered) {
        navigate("/confirm")
    }
    return <ConfigProvider theme={HeaderThemes.loginForm}>
        <Form className={s.form}
              name="basic"
              layout="vertical"
              onFinish={async (values) => {
                  formdata.append("username", values.email);
                  formdata.append("email", values.email);
                  formdata.append("password", values.password);
                  let myHeaders = new Headers();
                  let requestOptions = {
                      method: 'POST',
                      headers: myHeaders,
                      body: formdata,
                      redirect: 'follow',
                      withCredentials: true
                  }
                  dispatch(register(requestOptions))
              }}
              autoComplete="off"
              requiredMark={false}
        >
            <h1>Регистрация</h1>
            <Form.Item
                name="email"
                label="Почта пользователя"
                rules={[
                    {
                        type: 'email',
                        message: "Введенный адрес электронной почты неверен!",
                    },
                    {
                        required: true,
                        message: "Введите свой E-mail!",
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name="password"
                label="Пароль"
                rules={[
                    {
                        required: true,
                        message: "Введите свой пароль!",
                    },
                ]}
                hasFeedback
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item
                name="confirm"
                label="Подтвердите Пароль"
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: "Подтвердите свой пароль!",
                    },
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error("Пароли должны совпадать!"));
                        },
                    }),
                ]}
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item

            >
                <div style={{marginTop: "30px"}} className={s.nav}>
                    <Button htmlType="submit">
                        Подтвердить
                    </Button>
                    {registerError && <div className={s.error}>{registerError}</div>}
                </div>

            </Form.Item>
            <div className={s.center}>
                <Button onClick={() => navigate("/login")}
                        type="link">Авторизация</Button>
            </div>
        </Form>
        <Hints/>
    </ConfigProvider>
}
const Hints = () => {
    return (
        <div className={s.form} style={{maxWidth: "518px"}}>
            <h1>Часто возникающие проблемы</h1>
            <div>
                <ul>
                    <li>
                        Логин - уникальный псевдоним пользователя. Если при регистрации приходит уведомление, что
                        пользователь с таким логином уже существует - немного отредактируйте существующий. Также в
                        качестве
                        псевдонима можно использовать свою почту
                    </li>
                    <li>
                        Использование пробелов в логине запрещено
                    </li>
                    <li>
                        Пароль должен имень от 8 символов, содержать хотя бы одну букву и одну цифру.
                    </li>
                    <li>
                        Простые пароли (например, qwerty1) система также может не принять
                    </li>
                    <li>
                        Если при отправке данных появилось сообщение "Что-то пошло не так" - попробуйте поменять данные или
                        напишите в тех. поддержку запрос с текущими
                    </li>
                </ul>
            </div>

        </div>
    )
}
export default AccRegistration

