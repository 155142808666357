import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAILURE, RESET_REGISTER
} from '../types';

const initialState = {
    isAuthenticated: false,
    loading: false,
    error: null,
    registerLoading: false,
    registerError: null,
    isRegistered: false
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return { ...state, loading: true, error: null };
        case LOGIN_SUCCESS:
            return { ...state, isAuthenticated: true, loading: false };
        case LOGIN_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case LOGOUT:
            return { ...state, isAuthenticated: false };
        case REGISTER_REQUEST:
            return { ...state, registerLoading: true, registerError: null };
        case REGISTER_SUCCESS:
            return { ...state, isRegistered: true, registerLoading: false };
        case REGISTER_FAILURE:
            return { ...state, registerLoading: false, registerError: action.payload };
        case RESET_REGISTER:
            return { ...state, isRegistered: false};
        default:
            return state;
    }
};