import {SET_USER_DATA, SET_USER_DATA_ERROR} from "../types";
import axios from "axios";

export const setUserData = (config) => {
    return async dispatch => {
        try {
            const response = await axios.request(config);
            dispatch({
                type: SET_USER_DATA,
                payload: {
                    username: response.data.username,
                    id: response.data.id,
                    groups: response.data.groups,
                    isAdmin: response.data.is_superuser,
                    isStaff: response.data.is_staff
                }
            });
        } catch (error) {
            dispatch({type: SET_USER_DATA_ERROR});
        }
    };
};
