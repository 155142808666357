import './App.css';
import Footer from "./Components/Footer/Footer";
import {BrowserRouter, Navigate, Route, Routes, useLocation} from "react-router-dom";
import Main from "./Components/MainPages/Main";
import Library from "./Components/MainPages/Library";
import Questions from "./Components/MainPages/Questions";
import Webinars from "./Components/MainPages/Webinars";
import Teachers from "./Components/MainPages/Teachers";
import Cabinet from "./Components/MainPages/Cabinet";
import Courses from "./Components/MainPages/Courses";
import CoursePartExample from "./Components/CoursesExamples/CoursePartExample";
import Library2 from "./Components/LibraryPages/Library2";
import Library3 from "./Components/LibraryPages/Library3";
import Library4 from "./Components/LibraryPages/Library4";
import OrganizationInfo from "./Components/MainPages/OrganizationInfo";
import NewHeader from "./Components/NewHeader/NewHeader";
import {useEffect, useState} from "react";
import NewMobileHeader from "./Components/NewHeader/NewMobileHeader";
import Contacts from "./Components/MainPages/Contacts";
import Feedback from "./Components/MainPages/Feedback";
import OpecaCourse1 from "./Components/CoursesExamples/OpecaCourse1";
import OpecaCourse2 from "./Components/CoursesExamples/OpecaCourse2";
import MediationCourseFree from "./Components/CoursesExamples/MediationCourseFree";
import MediationCourse1 from "./Components/CoursesExamples/MediationCourse1";
import MediationCourse2 from "./Components/CoursesExamples/MediationCourse2";
import MediationCourse3 from "./Components/CoursesExamples/MediationCourse3";
import MediationCourse4 from "./Components/CoursesExamples/MediationCourse4";
import PdpCourse1 from "./Components/CoursesExamples/PdpCourse1";
import PdpCourse2 from "./Components/CoursesExamples/PdpCourse2";
import FilterCourses from "./Components/CoursesExamples/FilterCourses";
import Login from "./Components/MainPages/Login";
import {Provider, useDispatch, useSelector} from "react-redux";
import CabinetEdit from "./Components/MainPages/CabinetEdit";
import AccRegistration from "./Components/MainPages/AccRegistration";
import AccRegistrationConfirm from "./Components/MainPages/AccRegistrationConfirm";
import TeenagersCourse1 from "./Components/CoursesExamples/UnknownCourse";
import MC2Information from "./Components/Courses/MediationCourse2/MC2Information";
import ErrorPage from "./Components/MainPages/ErrorPage";
import NoAccessPage from "./Components/MainPages/NoAccessPage";
import ForgetPassword from "./Components/MainPages/ForgetPassword";
import ResetPasswordConfirm from "./Components/MainPages/ResetPasswordConfirm";
import NewPassword from "./Components/MainPages/NewPassword";
import NewPasswordConfirm from "./Components/MainPages/NewPasswordConfirm";
import OC1Information from "./Components/Courses/OpecaCourse1/OC1Information";
import Library5 from "./Components/LibraryPages/Library5";
import Library1 from "./Components/LibraryPages/Library1";
import Activation from "./Components/MainPages/Activation";
import Zayavka1 from "./Components/MainPages/Zayavka";
import ZayavkaFree from "./Components/MainPages/ZayavkaFree";
import ZayavkaConfirm from "./Components/MainPages/ZayavkaConfirm";
import PDP2Information from "./Components/Courses/PdpCourse2/PDP2Information";
import MC4Information from "./Components/Courses/MediationCourse4/MC4Information";
import {LOGIN_FAILURE, LOGIN_SUCCESS, SET_USER_DATA_ERROR} from "./Redux/types";
import {setUserData} from "./Redux/user/userActions";
import {store} from "./Redux/store";
import Zayavka from "./Components/MainPages/Zayavka";


function App() {
    const dispatch = useDispatch();
    const {isAuthenticated} = useSelector((state) => state.auth);

    useEffect(() => {
        const fetchData = async () => {
            if (
                localStorage.getItem("access") &&
                localStorage.getItem("access") !== "undefined"
            ) {
                dispatch({type: LOGIN_SUCCESS});
                let config = {
                    method: 'GET',
                    maxBodyLength: Infinity,
                    url: 'https://dpo.fcprc.ru/api/usersdata',
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem("access")}`,
                    }
                };
                dispatch(setUserData(config));
            } else {
                dispatch({type: LOGIN_FAILURE});
                dispatch({type: SET_USER_DATA_ERROR});
            }
        };
        fetchData();
    }, [isAuthenticated]);

    return (
        <div>
            <div style={{minHeight: "calc(100vh - 217px)"}}>
                {getIsMobile() ? <NewHeader/> : <NewMobileHeader/>}
                <Routes>
                    <Route element={<Main/>} path="/"/>
                    <Route element={<Library title="Библиотека" num1={0} num2={21}/>} path="/library"/>
                    <Route element={<Questions/>} path="/questions"/>
                    <Route element={<Webinars/>} path="/calendar"/>
                    <Route element={<Teachers/>} path="/teachers"/>
                    <Route path="/cabinet" element={<Cabinet/>}/>
                    <Route path="/edit" element={<CabinetEdit/>}/>
                    <Route element={<Courses/>} path="/courses"/>

                    <Route element={<CoursePartExample/>} path="/course1/part1"/>
                    <Route element={<Library1 title="Законодательства и иные нормативные акты" num1={17} num2={21}/>}
                           path="/library_cat/zakonodatelstva-i-inye-normativnye-akty"/>
                    <Route element={<Library2/>} path="/library_cat/mezhdunarodnye-dokumenty"/>
                    <Route element={<Library3 title="Обзоры практики Верховного Суда Российской Федерации"/>}
                           path="/library_cat/obzory-praktiki-verhovnogo-suda-rossijskoj-federaczii"/>
                    <Route element={<Library4/>} path="/library_cat/rekomenduemaya-literatura"/>
                    <Route element={<Library5 title="Наши методические разработки" num1={1} num2={17}/>}
                           path="/library_cat/nashi-metodicheskie-razrabotchiki"/>
                    <Route element={<OrganizationInfo/>} path="/dataeducation"/>
                    <Route element={<Contacts/>} path="/contacts"/>
                    <Route element={<Feedback/>} path="/feedback"/>
                    <Route element={<OpecaCourse1/>} path="/opecacourse1"/>
                    <Route element={
                        <RequireCourse group={[6]}>
                            <OC1Information/>
                        </RequireCourse>
                    } path="/opecacourse1/info"/>
                    <Route element={<OpecaCourse2/>} path="/opecacourse2"/>
                    <Route path="/mediationcourse1"
                           element={
                               <MediationCourse1/>
                           }
                    />
                    <Route path="/mediationcourse-free"
                           element={
                               <MediationCourseFree/>
                           }
                    />
                    <Route element={<MediationCourse2/>} path="/mediationcourse2"/>
                    <Route element={
                        <RequireCourse group={[4]}>
                            <MC2Information/>
                        </RequireCourse>
                    } path="/mediationcourse2/info"/>

                    <Route element={<MediationCourse3/>} path="/mediationcourse3"/>
                    <Route element={<MediationCourse4/>} path="/mediationcourse4"/>
                    <Route element={
                        <RequireCourse group={[2]}>
                            <MC4Information/>
                        </RequireCourse>
                    } path="/mediationcourse4/info"/>
                    <Route element={<PdpCourse1/>} path="/pdpcourse1"/>
                    <Route element={<PdpCourse2/>} path="/pdpcourse2"/>
                    <Route element={
                        <RequireCourse group={[10]}>
                            <PDP2Information/>
                        </RequireCourse>
                    } path="/pdpcourse2/info"/>
                    <Route element={<TeenagersCourse1/>} path="/teenagerscourse1"/>
                    <Route element={<FilterCourses otdel={"Опека и попечительство"}/>} path="/courses/opeca"/>
                    <Route element={<FilterCourses otdel="Медиация и медиативные технологии"/>}
                           path="/courses/mediation"/>
                    <Route element={<FilterCourses otdel="Профилактика девиантного поведения"/>} path="/courses/pdp"/>

                    <Route element={<Zayavka1/>} path="/registration"/>
                    <Route element={<AccRegistration/>} path="/acc_registration"/>
                    <Route element={<ZayavkaFree/>} path="/registration-free"/>
                    <Route element={<AccRegistrationConfirm/>} path="/confirm"/>
                    <Route path="/login"
                           element={
                                   <Login/>
                           }
                    />

                    <Route element={<ErrorPage/>} path="*"/>
                    <Route element={<NoAccessPage/>} path="/forbidden"/>
                    <Route element={<ForgetPassword/>} path="/reset-password"/>
                    <Route element={<ResetPasswordConfirm/>} path="/reset-password-confirm"/>
                    <Route element={<NewPasswordConfirm/>} path="/new-password-confirm"/>
                    <Route element={<ZayavkaConfirm/>} path="/zayavka-confirm"/>
                    <Route element={<NewPassword/>} path="/password/reset/confirm/:uid/:token"/>
                    <Route element={<Activation/>} path="/activate/:uid/:token"/>
                </Routes>
                <Footer/>
            </div>
        </div>
    );


}

const DpoApp = () => {
    return <BrowserRouter>
        <Provider store={store}>
            <App/>
        </Provider>
    </BrowserRouter>
}



const getIsMobile = () => window.innerWidth > 976;

function RequireCourse(props) {
    const user = useSelector((state) => state.user);
    const {group, children} = props
    let location = useLocation();

    const found = user.groups !== [] ? findCommonElement(user.groups, group) : false
    const res = user.isAdmin && user.isStaff && user.groups && user.isAdmin !== true && user.isStaff !== true && found === false
    if (res === null || res === true) {
        return <Navigate to="/" state={{from: location}} replace/>;
    }
    return children;
}

export function findCommonElement(array1, array2) {

    // Loop for array1
    for (let i = 0; i < array1.length; i++) {

        // Loop for array2
        for (let j = 0; j < array2.length; j++) {

            // Compare the element of each and
            // every element from both of the
            // arrays
            if (array1[i] === array2[j]) {
                console.log(true)
                // Return if common element found
                return true;
            }
        }
    }

    // Return if no common element exist
    return false;
}



export default DpoApp;
