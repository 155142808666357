import s from "../Modules/Modules.module.css";
import m from "./CourseEx.module.css"
import {CourseDescription, PageTitle, SubTitle} from "../Modules/Modules";
import {TeacherBlock} from "./CourseExample1";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {setUserData} from "../../Redux/user/userActions";
import axios from "axios";

const TeenagersCourse1 = () => {

    return (
        <div className={m.main}>
            <div className={m.coursePage}>
                <div className={m.courseLeft}>
                    <SubTitle title="Подростковые центры"/>
                    <PageTitle
                        title="Организация подросткового центра как современного пространства для развития, социализации и самореализации подростков"/>
                    <TeacherBlock name="Ирина Муравьева"
                                  position='Ведущий аналитик отдела образовательных курсов и просветительских материалов'/>
                    {window.innerWidth < 976 ?
                        <div className={m.courseRight}>
                            <p></p>
                            <BuyWindow/>
                        </div> : <></>
                    }
                    <p></p>
                    {/*<div className={m.courseImg}>*/}
                    {/*    <img src={bc} alt=""/>*/}
                    {/*</div>*/}
                    <CourseDescription title="Описание">
                        <CourseDesc/>
                    </CourseDescription>
                </div>
                {window.innerWidth >= 976 ?
                    <div className={m.courseRight}>
                        <BuyWindow/>
                    </div> : <></>
                }
            </div>
        </div>
    )
}
export default TeenagersCourse1


const CourseDesc = () => {
    return <div className={s.courseDescription}>
        Дополнительная профессиональная программа повышения квалификации (далее – программа) рассчитана на команды
        подростковых центров, которые планируют открывать или уже открыли подростковый центр. Обучение проходят как
        руководители, так и сотрудники, чтобы понимать полный цикл работы центра
        <p></p>
        Ценность:
        <ul>
            <li>
                после курса вы получите базовые знания по открытию подростковых пространств и принципы их работы;
            </li>
            <li>
                на практике узнаете, что такое восстановительный подход в социально-психологической работе с детьми;
            </li>
            <li>
                сформируете насмотренность подростковых центров, форматов и оформления;
            </li>
            <li>
                познакомитесь с действующими руководителями подростковых центров, получите возможность задать вопросы и
                побеседовать.
            </li>
        </ul>
        <strong>Цель программы:</strong><br style={{fontSize: "24px"}}/>
        Совершенствование (получение) новой компетенции и (или) повышение профессионального уровня в рамках имеющейся
        квалификации, необходимой для профессиональной деятельности руководителя по организации эффективного
        функционирования центра для подростков
        <p></p>
        <strong>Программа курса:</strong><br style={{fontSize: "24px"}}/>
        <ul>
            <li>
                1. Подросток и его окружение. Запрос на организацию подросткового центра
            </li>
            <li>
                2. Системный подход в организации работы подросткового центра. Заказчики и партнёры подросткового центра
            </li>
            <li>
                3. Организация работы подросткового центра. Нормативно-правовое и финансовое обеспечение центра,
                информационная политика центра
            </li>
            <li>
                4. Механизм и форматы подросткового центра и его кадровое обеспечение
            </li>
        </ul>
        <p></p>
        <strong>Преподаватели (планируемые):</strong><br style={{fontSize: "24px"}}/>
        <ul>
            <li>
                <b>Ирина Муравьева</b>, ведущий аналитик отдела образовательных курсов и просветительских материалов;
            </li>
            <li>
                <b>Ирина Халина</b>, ведущий аналитик отдела реализации проектов.
            </li>
        </ul>
        <p></p>
        <strong>Характеристики курса</strong><br style={{fontSize: "24px"}}/>
        Трудоемкость программы: 36 ак. часов <br/>
        Длительность (недель): 6<br/>
        Учебная нагрузка по курсу: до 3 часов в день<br/>
        Язык курса: русский<br/>
        Удостоверение установленного образца: да.
        <p></p>
        <strong>Для получения удостоверения о повышении квалификации необходимо:</strong><br
        style={{fontSize: "24px"}}/>
        <ul>
            <li>
                Зарегистрироваться на курс на портале Центра защиты прав и интересов детей;
            </li>
            <li>
                Заполнить шаблоны документов (<a target="_blank" href="https://dpo.fcprc.ru/media/media/behavior/Личное_заявление_физического_лица.docx">личное заявление физического лица</a>, <a target="_blank" href="https://dpo.fcprc.ru/media/media/behavior/Заявка_о_приеме_на_обучение_от_учреждения.docx">заявка о приеме на обучение от учреждения</a>, <a target="_blank" href="https://dpo.fcprc.ru/media/media/behavior/soglasie_pd.docx">согласие на обработку персональных данных</a> и <a target="_blank" href="https://dpo.fcprc.ru/media/media/behavior/soglasie_pd_subject.docx">согласие на обработку персональных данных, разрешенных субъектом персональных данных для распространения</a>) и прикрепить их в личном кабинете на портале (заявка от учреждения, личное
                заявление обучающегося, два согласия на обработку персональных данных);
            </li>
            <li>
                Прикрепить в личном кабинете на портале сканы паспорта, СНИЛСа, диплома об образовании, документа о
                смене ФИО, если оно не совпадает в дипломе и паспорте;
            </li>
            <li>
                Отправить <strong>оригиналы</strong> заявлений и согласий по адресу: <strong>115093, г. Москва, Щипок, д.18,
                ФГБУ Центр защиты прав и интересов детей, Отдел ДПО (подростки)</strong>;
            </li>
            <li>
                Посещение онлайн-занятий, изучение материалов на учебной платформе;
            </li>
            <li>
                Успешная защита итогового проекта в группе.
            </li>
        </ul>
    </div>
}

const BuyWindow = () => {
    const {isAuthenticated} = useSelector(state => state.auth);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const fetchData = async () => {
        let data1 = {
            groups: user.groups.concat([9])
        };
        let config1 = {
            method: 'patch',
            maxBodyLength: Infinity,

            url: 'https://dpo.fcprc.ru/api/usersdata',
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("access")}`,
            },
            data: JSON.stringify(data1)
        };
        axios.request(config1)

        let config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: 'https://dpo.fcprc.ru/api/usersdata',
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${localStorage.getItem("access")}`,
            }
        };
        dispatch(setUserData(config));
    }

    return (
        <div className={m.buy}>
            <div className={m.info}>
                <div style={{fontSize: "18px", paddingBottom: "5px"}}>
                    <strong>Принять участие</strong>
                </div>
                <div style={{fontSize: "12px", paddingBottom: "30px", color: "#808080"}}>
                    36 академических часов
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "10px"
                }}>
                    <div style={{fontSize: "12px", color: "#808080"}}>
                        Стоимость курса
                    </div>
                    <div style={{fontSize: "18px"}}>
                        <strong>Бюджетный</strong>
                    </div>
                </div>
                <div className={m.buyButton}>
                    {isAuthenticated ?
                        !user.groups.includes(9) ? <NavLink onClick={() => fetchData()}>Записаться</NavLink> : <NavLink to="#">Вы записаны!</NavLink> :
                        <NavLink to="/acc_registration">Регистрация</NavLink>
                    }
                </div>
            </div>
        </div>
    )
}
