export const HeaderThemes = {
    login: {
        components: {
            Button: {
                defaultBg: 'rgba(56, 95, 235, 1)',
                defaultHoverBg: 'white',
                defaultActiveBg: 'white',
                defaultColor: "white",
                defaultActiveColor: "rgba(56, 95, 235, 1)",
                defaultHoverColor: "rgba(56, 95, 235, 1)",
                colorBorder: "none",
                defaultHoverBorderColor: "rgba(56, 95, 235, 1)",
                defaultActiveBorderColor: "none",
                contentFontSize: "20px",
                paddingBlock: "25px",
                paddingInline: "25px"

            }
        }
    },
    loginMobile: {
        components: {
            Button: {
                defaultBg: 'rgba(56, 95, 235, 1)',
                defaultHoverBg: 'white',
                defaultActiveBg: 'white',
                defaultColor: "white",
                defaultActiveColor: "rgba(56, 95, 235, 1)",
                defaultHoverColor: "rgba(56, 95, 235, 1)",
                colorBorder: "none",
                defaultHoverBorderColor: "rgba(56, 95, 235, 1)",
                defaultActiveBorderColor: "none",
                contentFontSize: "20px",
                paddingBlock: "25px",
                paddingInline: "45px",
                paddingInlineLG: "45px",
                paddingInlineSM: "45px",

            }
        }
    },
    languages: {
        components: {
            Button: {
                defaultColor: "#000000",
                defaultActiveColor: "#00B4c0",
                defaultHoverColor: "#00B4c0",
                textHoverBg: "none",
            },
            Select: {
                fontSize: 20,
                optionPadding: "5px 30px"
            }
        }
    },
    nav: {
        token: {
            colorText: '#4D4D4D',
            colorPrimary: '#4D4D4D',
        },
        components: {
            Menu: {
                colorText: '#4D4D4D',
                colorPrimary: '#4D4D4D',
                horizontalItemSelectedColor: "#385FEB",
                horizontalItemHoverColor: "#385FEB",
                itemSelectedColor: "#385FEB",
                subMenuTitleColor: '#385FEB',
                inlineIndent: 20,
                padding: 20
            }
        }
    },
    modalActive: {
        components: {
            Button: {
                defaultBg: 'white',
                defaultHoverBg: 'white',
                defaultActiveBg: 'white',
                defaultColor: "rgba(56, 95, 235, 1)",
                defaultActiveColor: "rgba(56, 95, 235, 1)",
                defaultHoverColor: "rgba(56, 95, 235, 1)",
                colorBorder: "rgba(56, 95, 235, 1)",
                defaultHoverBorderColor: "rgba(56, 95, 235, 1)",
                defaultActiveBorderColor: "none",
                contentFontSize: "20px",
                paddingBlock: "25px",
            }
        }
    },
    modalDisable: {
        components: {
            Button: {
                defaultBg: 'white',
                defaultHoverBg: 'white',
                defaultActiveBg: 'white',
                defaultColor: "#4d4d4d",
                defaultActiveColor: "rgba(56, 95, 235, 1)",
                defaultHoverColor: "rgba(56, 95, 235, 1)",
                colorBorder: "none",
                defaultHoverBorderColor: "none",
                defaultActiveBorderColor: "none",
                contentFontSize: "20px",
                paddingBlock: "25px",
            }
        }
    },
    loginForm: {
        components: {
            Form: {
                labelFontSize: 20,
                fontSize: 20
            },
            Input: {
                fontSize: 24,
                paddingBlock: "10px",
                paddingInline: "20px",
            },
            Button: {
                defaultBg: '#df7a9a',
                defaultHoverBg: 'white',
                defaultActiveBg: 'white',
                defaultColor: "white",
                defaultActiveColor: "#df7a9a",
                defaultHoverColor: "#df7a9a",
                colorBorder: "none",
                defaultHoverBorderColor: "#df7a9a",
                defaultActiveBorderColor: "none",
                contentFontSize: "20px",
                paddingBlock: "25px",
                paddingInline: "45px",
                paddingInlineLG: "45px",
                paddingInlineSM: "45px",

            }
        }
    }
}