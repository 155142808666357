import { createStore, applyMiddleware, compose, combineReducers } from 'redux';

import { authReducer } from './auth/authReducer';
import {thunk} from "redux-thunk";
import {userReducer} from "./user/userReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
window.__store__ = store;

