import s from "../Modules/Modules.module.css";
import m from "./CourseEx.module.css"
import {CourseDescription, CourseFilter, PageTitle, SubTitle} from "../Modules/Modules";
import {TeacherBlock} from "./CourseExample1";
import opeca from "../../assets/Imgs/opeca.jpg"
import {findCommonElement} from "../../App";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";

const OpecaCourse1 = () => {
    const user = useSelector((state) => state.user);
    const groupsId = [6]

    return (
        <div className={m.main}>
            <div className={m.coursePage}>
                <div className={m.courseLeft}>
                    <SubTitle title="Опека и попечительство"/>
                    <PageTitle
                        title="Рассмотрение споров родителей, связанных с воспитанием детей, включая споры с участием иностранного элемента"/>
                    <TeacherBlock  name="Марина Валерьевна Лашкул" position='Заместитель директора по вопросам опеки и попечительства ФГБУ "Центр защиты прав и интересов детей"'/>
                    {(window.innerWidth < 976 && (checkGroup(user, groupsId) === true || user.isAdmin === true || user.isStaff === true)) ?
                        <div className={m.courseRight}>
                            <p></p>
                            <CourseFilter filterArray={OC1Array}/>
                        </div> : <></>
                    }
                    <div className={m.courseImg}>
                        <img src={opeca} alt=""/>
                    </div>
                    <CourseDescription title="Описание">
                        <CourseDesc/>
                    </CourseDescription>
                    {/*<CourseDescription title="Раздел 1.">*/}
                    {/*    <Razdel/>*/}
                    {/*</CourseDescription>*/}
                    {/*<CourseDescription title="Раздел 2.">*/}
                    {/*</CourseDescription>*/}
                    {/*<CourseDescription title="Календарь">*/}
                    {/*    <CalendarExample/>*/}
                    {/*</CourseDescription>*/}
                </div>
                <div className={m.courseRight}>
                    {
                        (window.innerWidth >= 976 && (checkGroup(user, groupsId) === true || user.isAdmin === true || user.isStaff === true))?
                            <CourseFilter filterArray={OC1Array}/>
                            : <></>
                    }
                </div>
            </div>
        </div>
    )
}
export default OpecaCourse1


const CourseDesc = () => {
    return <div className={s.courseDescription}>

        Дополнительная профессиональная программа повышения квалификации (далее - программа) направлена на рассмотрение
        споров:
        <ul>
            <li>
                об ограничении и лишении родительских прав, об отмене ограничения и восстановлении родительских прав
            </li>
            <li>
                о месте жительства ребенка и порядке общения с ребенком отдельно проживающего родителя
            </li>
            <li>
                о незаконном похищении/удержании ребенка одним из родителей
            </li>
        </ul>
        Программа предназначена для повышения квалификации работников органов опеки и попечительства,
        осуществляющих
        содействие обеспечению и защите прав и законных интересов детей.
        <p></p>
        <strong>Цель программы:</strong><br style={{fontSize: "24px"}}/>
        Целью программы является получение правовых знаний об особенностях деятельности органов опеки и
        попечительства
        при рассмотрении споров о воспитании детей, в том числе с участием иностранного элемента.
        <p></p>
        <strong>Программа курса:</strong><br style={{fontSize: "24px"}}/>
        <ul>
            <li>
                Правовые основания и направления деятельности органов опеки и попечительства при рассмотрении
                споров о
                воспитании детей (на досудебной стадии, в судебном процессе, в исполнительном производстве).
            </li>
            <li>
                Учет мнения ребенка при решении вопросов, затрагивающих его права и интересы. Методы и способы
                выяснения
                мнения ребенка в случаях, предусмотренных законодательством.
            </li>
            <li>
                Законодательство, применимое при рассмотрении споров, связанных с воспитанием детей с участием
                иностранного
                элемента
            </li>
            <li>
                Семейная медиация. Медиация в трансграничных семейных спорах
            </li>
            <li>
                Итоговая аттестация
            </li>
        </ul>
        <p></p>
        <strong>Преподаватели (планируемые):</strong><br style={{fontSize: "24px"}}/>
        <ul>
            <li>
                <b>Галина Владимировна Семья</b>, доктор психологических наук, профессор;
            </li>
            <li>
                <b>Марина Валерьевна Лашкул</b>, заместитель директора по вопросам опеки и попечительства;
            </li>
            <li>
                <b>Анна Евгеньевна Тарасова</b>, кандидат юридических наук, юрист;
            </li>
            <li>
                <b>Бобылёва Ирина Анатольевна</b>, аналитик;
            </li>
            <li>
                <b>Сергей Леонидович Тюльканов</b>, аналитик.
            </li>
        </ul>
        <p></p>
        <strong>Характеристики курса</strong><br style={{fontSize: "24px"}}/>
        Трудоемкость программы: 36 ак. часов <br/>
        Длительность (недель): 2<br/>
        Учебная нагрузка по курсу: до 4 часов в день.<br/>
        Язык курса: русский<br/>
        Удостоверение установленного образца: да<br/>
        Календарный план: апрель, октябрь
        <p></p>
        <strong>Планируемый период обучения: с 15 апреля по 30 апреля 2024 года</strong><br style={{fontSize: "24px"}}/>
        Расписание занятий:<br/>
        10.00 – 11.30 – 1 пара<br/>
        11.30 – 11.40 – перерыв<br/>
        11.40 – 13.10 – 2 пара
    </div>
}
export function checkGroup(user, gr) {
    const found = user.groups !== [] ? findCommonElement(gr, user.groups) : false
    return found
}

const BuyWindow = () => {
    return (
        <div className={m.buy}>
            <div className={m.info}>
                <div style={{fontSize: "18px", paddingBottom: "5px"}}>
                    <strong>Принять участие</strong>
                </div>
                {/*<div style={{fontSize: "16px", paddingBottom: "10px"}}>*/}
                {/*    <span><strong>0 человек</strong></span> прошли курс*/}
                {/*</div>*/}
                <div style={{fontSize: "12px", paddingBottom: "30px", color: "#808080"}}>
                    36 академических часов
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "10px"
                }}>
                    <div style={{fontSize: "12px", color: "#808080"}}>
                        Стоимость курса
                    </div>
                    <div style={{fontSize: "18px"}}>
                        <strong>15 000 ₽</strong>
                    </div>
                </div>
                <div className={m.buyButton}>
                    <NavLink to="#">Записаться на курс</NavLink>
                </div>
            </div>
        </div>
    )
}
export const OC1Array = [
    {
        title: "Описание курса",
        url: "/opecacourse1",
    },
    {
        title: "Информация для обучающихся",
        url: "/opecacourse1/info"
    },
]
