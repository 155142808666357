import {SET_USER_DATA, SET_USER_DATA_ERROR} from '../types';

const initialState = {
    username: "",
    id: "",
    groups: [],
    isAdmin: false,
    isStaff: false
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                username: action.payload.username,
                id: action.payload.id,
                groups: action.payload.groups,
                isAdmin: action.payload.isAdmin,
                isStaff: action.payload.isStaff
            };
        case SET_USER_DATA_ERROR:
            return {
                username: "error",
                id: "error",
                groups: [],
                isAdmin: '',
                isStaff: ''
            };
        default:
            return state;
    }
};