import s from "./Main.module.css"
import {HumanInfo, PageTitle, YourCourses} from "../Modules/Modules";
import FullCalendar from "@fullcalendar/react";
import ruLocale from "@fullcalendar/core/locales/ru";
import dayGridPlugin from "@fullcalendar/daygrid";
import tippy from "tippy.js";
import {EditOutlined, LogoutOutlined} from "@ant-design/icons";
import {NavLink, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import axios from "axios";
import {logout} from "../../Redux/auth/authActions";


const Cabinet = () => {

    const [lastname, setLastname] = useState('-')
    const [name, setName] = useState('')
    const [middlename, setMiddlename] = useState('')
    const [mail, setMail] = useState('-')
    const [city, setCity] = useState('-')
    const [course, setCourse] = useState('-')
    const [groups, setGroups] = useState([])
    const {isAuthenticated} = useSelector(state => state.auth);
    let navigate = useNavigate();

    useEffect(() => {
        if (
            !localStorage.getItem("access") ||
            localStorage.getItem("access") === "undefined"
        ) {
            navigate('/login');
        }
    }, [isAuthenticated]);

    useEffect(() => {
        let config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: 'https://dpo.fcprc.ru/api/usersdata',
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${localStorage.getItem("access")}`,
            }
        };
        axios.request(config)
            .then((response) => {
                setLastname(response.data.фамилия)
                setName(response.data.имя)
                setMiddlename(response.data.отчество)
                setMail(response.data.почта)
                setCity(response.data.город)
                setCourse(response.data.курс)
                setGroups(response.data.groups)

            })
    }, []);

    function setGroupList(groups) {
        let array = []
        for (let i = 0; i < groups.length; i++) {
            if (groups[i] === 10) {
                let ob = {
                    title: "Психологические проблемы детско-подросткового возраста: профилактика и коррекция в общеобразовательных организациях",
                    grade: 0,
                    progress: 0,
                    url: "/pdpcourse2"
                }
                array.push(ob)
            }
            if (groups[i] === 4) {
                let ob = {
                    title: "Практикум начинающего медиатора: учимся на кейсах",
                    grade: 0,
                    progress: 0,
                    url: "/mediationcourse2"
                }
                array.push(ob)
            }
            if (groups[i] === 6) {
                let ob = {
                    title: "Рассмотрение споров родителей, связанных с воспитанием детей, включая споры с участием иностранного элемента",
                    grade: 0,
                    progress: 0,
                    url: "/opecacourse1"
                }
                array.push(ob)
            }
            if (groups[i] === 3) {
                let ob = {
                    title: "Основы медиации для специалистов по работе с семьей, органов опеки и попечительства",
                    grade: 0,
                    progress: 0,
                    url: "/mediationcourse1"
                }
                array.push(ob)
            }
            if (groups[i] === 5) {
                let ob = {
                    title: "Практикум профессионального медиатора: тренируемся на кейсах",
                    grade: 0,
                    progress: 0,
                    url: "/mediationcourse3"
                }
                array.push(ob)
            }
            if (groups[i] === 2) {
                let ob = {
                    title: "Медиативно-восстановительные технологии в образовательной организации",
                    grade: 0,
                    progress: 0,
                    url: "/mediationcourse4"
                }
                array.push(ob)
            }
            if (groups[i] === 7) {
                let ob = {
                    title: "Особенности подготовки и сопровождения опекунов или попечителей несовершеннолетних",
                    grade: 0,
                    progress: 0,
                    url: "/opecacourse2"
                }
                array.push(ob)
            }
            if (groups[i] === 8) {
                let ob = {
                    title: "Формирование ценностей здоровьесбережения и профилактика зависимого поведения обучающихся образовательных организаций",
                    grade: 0,
                    progress: 0,
                    url: "/pdpcourse1"
                }
                array.push(ob)
            }
            if (groups[i] === 9) {
                let ob = {
                    title: "Организация подросткового центра как современного пространства для развития, социализации и самореализации подростков",
                    grade: 0,
                    progress: 0,
                    url: "/teenagerscourse1"
                }
                array.push(ob)
            }
        }
        return array
    }

    function setUrl(title) {
        switch (title) {
            case "Основы медиации для специалистов по работе с семьей, органов опеки и попечительства":
                return "/mediationcourse1"

            case "Практикум начинающего медиатора: учимся на кейсах":
                return "/mediationcourse2"

            case "Практикум профессионального медиатора: тренируемся на кейсах":
                return "/mediationcourse3"

            case "Медиативно-восстановительные технологии в образовательной организации":
                return "/mediationcourse4"

            case "Рассмотрение споров родителей, связанных с воспитанием детей, включая споры с участием иностранного элемента":
                return "/opecacourse1"

            case "Особенности подготовки и сопровождения опекунов или попечителей несовершеннолетних":
                return "/opecacourse2"

            case "Формирование ценностей здоровьесбережения и профилактика зависимого поведения обучающихся образовательных организаций":
                return "/pdpcourse1"

            case "Психологические проблемы детско-подросткового возраста: профилактика и коррекция в общеобразовательных организациях":
                return "/pdpcourse2"

            case "Организация подросткового центра как современного пространства для развития, социализации и самореализации подростков":
                return "/teenagerscourse1"
            default:
                return "#"
        }
    }

    return (
        <div className={s.main}>
            <div className={s.cabinetTitle}>
                <div className={s.left}>
                    <PageTitle title="Личный кабинет"/>
                </div>
                {
                    window.innerWidth > 976 ?
                        <EditButton/>
                        : <></>
                }

            </div>
            <HumanInfo lastname={lastname} name={name} middlename={middlename} city={city} mail={mail}/>
            <YourCourses list={setGroupList(groups)}/>
            {/*<CourseDescription title="Календарь">*/}
            {/*    <CalendarExample/>*/}
            {/*</CourseDescription>*/}
            {
                window.innerWidth <= 976 ?
                    <SmallPanel/>
                    : <LogOutButton/>
            }
        </div>
    )
}

export const CalendarExample = () => {
    return <div>
        <FullCalendar

            locale={ruLocale}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            weekends={true}
            height={600}
            headerToolbar={{
                left: 'title',
                center: '',
                right: 'prev,next'
            }}
            events={[
                {
                    title: 'Тест 1',
                    date: '2023-12-01',
                    description: "Тестовое описание"
                },
                {
                    title: 'Тест 2',
                    date: '2023-12-02',
                    description: "Споры родителей, связанные с воспитанием детей. Особенности рассмотрения споров, связанных с воспитанием детей, с участием иностранного элемента"
                },
                {
                    title: 'Long Event',
                    description: 'description for Long Event',
                    start: '2023-12-07',
                    end: '2023-12-10'
                }
            ]}
            eventMouseEnter={function (info) {
                const getStringMonth = (month) => {
                    switch (month) {
                        case(0):
                            return 'января'
                        case(1):
                            return 'февраля'
                        case(2):
                            return 'марта'
                        case(3):
                            return 'апреля'
                        case(4):
                            return 'мая'
                        case(5):
                            return 'июня'
                        case(6):
                            return 'июля'
                        case(7):
                            return 'августа'
                        case(8):
                            return 'сентября'
                        case(9):
                            return 'октября'
                        case(10):
                            return 'ноября'
                        case(11):
                            return 'декабря'
                        default:
                            return ''
                    }
                }
                tippy(info.el, {
                    content: "<div style='color: #3F5070; font-weight: 600'>" + info.event.start.getDate() + " " + getStringMonth(info.event.start.getMonth()) + " " + info.event.start.getFullYear() + "</div>" +
                        "<div>" + info.event._def.extendedProps.description + "</div>",
                    //content: info.event._def.extendedProps.description,
                    theme: "edu",
                    allowHTML: "true"
                })
            }
            }

        />
        <p></p>
    </div>
}

export default Cabinet


const EditButton = () => {
    return <div className={s.right}>
        <div className={s.cabinetEdit}>
            <NavLink to="/edit"><EditOutlined style={{paddingRight: "10px", fontSize: "20px"}}/>Редактировать личные данные</NavLink>
        </div>
    </div>
}
const LogOutButton = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    return <div className={s.right}  onClick={() => {
        dispatch(logout());
        navigate("/");
        window.scrollTo(0, 0)
    }}>
        <div className={s.logout}>
            <button><LogoutOutlined style={{paddingRight: "10px", fontSize: "20px"}}/>Выйти из аккаунта</button>
        </div>
    </div>
}

const SmallPanel = () => {
    return (
        <div className={s.smallPanel}>
            <EditButton/>
            <LogOutButton/>
        </div>
    )
}