import s from "./NewLogin.module.css"
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, ConfigProvider, Form, Input} from "antd";
import {login} from "../../Redux/auth/authActions";
import {useNavigate} from "react-router-dom";
import {HeaderThemes} from "../../themes/HeaderThemes";

const Login = () => {
    const {error, isAuthenticated} = useSelector(state => state.auth);
    const dispatch = useDispatch();
    let formdata = new FormData();
    const navigate = useNavigate()

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/");
        }
    }, [isAuthenticated]);

    return <ConfigProvider theme={HeaderThemes.loginForm}>
        <Form className={s.form}
              name="login"
              layout="vertical"
              onFinish={async (values) => {
                  formdata.append("username", values.username);
                  formdata.append("password", values.password);
                  let myHeaders = new Headers();
                  let requestOptions = {
                      method: 'POST',
                      headers: myHeaders,
                      body: formdata,
                      redirect: 'follow',
                      withCredentials: true
                  }

                  dispatch(login(requestOptions))
              }}
              autoComplete="off"
              requiredMark={false}
        >
            <h1>Авторизация</h1>
            <Form.Item
                label="Почта пользователя"
                name="username"
                rules={[
                    {
                        required: true,
                        message: "Введите почту!",
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="Пароль"
                name="password"
                rules={[
                    {
                        required: true,
                        message: "Введите пароль!",
                    },
                ]}
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item

            >
                <div style={{marginTop: "30px"}} className={s.nav}>
                    <Button htmlType="submit">
                        Войти
                    </Button>
                    {error && <div className={s.error}>{error}</div>}
                </div>

            </Form.Item>
            <div className={s.center}>
                <Button onClick={() => navigate("/reset-password")}
                        type="link">Забыли пароль?</Button>
            </div>
            <div className={s.center}>
                <Button onClick={() => navigate("/acc_registration")}
                        type="link">Регистрация</Button>
            </div>
        </Form>
    </ConfigProvider>
}
export default Login

