import s from "./Carousel.module.css";
import n from "./News.module.css"
import React, {useEffect, useState} from "react";
import {GrayButton} from "./Buttons";
import {BigMainTitle} from "../MainPages/Main";
import {NavLink} from "react-router-dom";


export const CourseCarousel = (props) => {

    const {children} = props
    const [touchPosition, setTouchPosition] = useState(null)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [length, setLength] = useState(children.length)

// Set the length to match current children from props
    useEffect(() => {
        setLength(children.length)
    }, [children])

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }

    const next = () => {
        if (currentIndex < (length - 3)) {
            setCurrentIndex(prevState => prevState + 1)
        }
    }

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1)
        }
    }

    const nextSize = () => {
        const size = window.innerWidth
        switch (true) {
            case (size <= 625): {
                return `translateX(-${currentIndex * 110}%)`

            }
            case (size <= 976): {
                return `translateX(-${currentIndex * 55}%)`;
            }

            default : {
                return `translateX(-${currentIndex * 35}%)`
            }

        }
    }

    const handleTouchMove = (e) => {
        const touchDown = touchPosition

        if (touchDown === null) {
            return
        }

        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch

        if (diff > 5) {
            next()
        }

        if (diff < -5) {
            prev()
        }

        setTouchPosition(null)
    }

    return <div style={{height: "875px"}}>
        <div className={s.recommend}>
            <div className={s.left}>
                <div className={s.upper}>
                    <BigMainTitle title="Рекомендуемые курсы"/>
                </div>
                <div className={s.bottom}>
                    <span style={{color: "#CB2D5F"}}>Курсы повышения квалификации</span>
                </div>
            </div>
            <div className={s.right}>
                <GrayButton title="Смотреть все" url="/courses"/>
                <span className={s.rightBtns}>
                    <svg onClick={prev} width="40px" height="40px" viewBox="2 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                         style={{
                             border: "2px solid #cccccc",
                             float: "left",
                             borderRadius: "20px",
                             backgroundColor: "white"
                         }}>
                    <path d="M15 7L10 12L15 17" stroke="#cccccc" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    </svg>
                    <svg onClick={next} width="40px" height="40px" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                         style={{
                             border: "2px solid #cccccc",
                             float: "left",
                             borderRadius: "20px",
                             backgroundColor: "white"
                         }}>
                    <path d="M10 7L15 12L10 17" stroke="#cccccc" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    </svg>
                </span>
            </div>
        </div>
        <div className={s.carouselContainer} style={{paddingTop: "20px"}}>
            <div className={s.carouselWrapper}>
                <div className={s.carouselContentWrapper} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
                    <div className={`${s.carouselContent} ${s.show3}`}
                         style={{transform: nextSize()}}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export const CourseSlide = ({el}) => {
    return <div className={s.courseWindow}>
        <div className={s.courseImage}>
            <img src={el.картинка_курса} alt=""/>
        </div>
        <div className={s.word}>
            {el.направленность_курса}
        </div>
        <div className={s.courseTitle}>
            {el.заголовок}
        </div>
        <div className={s.info}>
                <span className={s.left}>
                   {el.описание} академических часов
                </span>
            <span className={s.right}>
                     {el.стоимость_курса}
                </span>
        </div>
        <div className={s.bottom}>
                <span className={s.left}>
                    <NavLink to={el.ссылка_на_страницу}>Описание</NavLink>
                </span>
            <span className={s.right}>
                    <NavLink to={el.текст_курса}>Записаться на курс</NavLink>
                </span>
        </div>
    </div>
}
export const NewsCard = ({el}) => {
    return <NavLink className={n.newsCard}>
        <div className={n.courseImage}>
            <img src={el.img} alt=""/>
        </div>
        <div className={n.courseTitle}>
            {el.title}
        </div>
        <div className={n.bottom}>
            {el.time}
        </div>
    </NavLink>
}
export const FreeCourseSlide = ({el}) => {
    return <div className={s.courseWindow}>
        <div className={s.courseImage}>
            <img src="https://dpo.fcprc.ru/media/media/BigCourse_1mZKUfg.png" alt=""/>
        </div>
        <div className={s.word}>
            Курс повышения квалификации
        </div>
        <div className={s.courseTitle}>
            Организация подросткового центра как современного пространства для развития, социализации и самореализации подростков
        </div>
        <div className={s.info}>
            <span className={s.left}>
                36 академических часов
            </span>
            <span className={s.right}>
                Бюджетный
            </span>
        </div>
        <div className={s.bottom}>
            <span className={s.left}>
                <NavLink to="/teenagerscourse1">Описание</NavLink>
            </span>
            <span className={s.right}>
                <NavLink to="/free_registration">Записаться на курс</NavLink>
            </span>
        </div>
    </div>
}

export const NewCourseCarousel = (props) => {
    const {children} = props
    return (
        <div>
            <div className={s.recommend}>
                <div className={s.left}>
                    <div className={s.upper}>
                        <BigMainTitle title="Рекомендуемые курсы"/>
                    </div>
                    <div className={s.bottom}>
                        <span style={{color: "#CB2D5F"}}>Дополнительное профессиональное образование</span>
                    </div>
                </div>

                <GrayButton title="Смотреть все" url="/courses"/>

            </div>
            <div className={s.newCarousel}>
                {children}
            </div>
        </div>
    )
}