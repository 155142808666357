import minpros from "../../assets/Footer/RFMP.png"
import s from "./NewHeader.module.css"
import {EyeOutlined, SearchOutlined} from "@ant-design/icons";
import icon from "../../assets/NewHeader/icon2.png"
import profile from "../../assets/NewHeader/profile.png"
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";

const NewHeader = () => {
    const {isAuthenticated} = useSelector(state => state.auth);
    return (
        <div className={s.header}>
            <div className={s.upper}>
                <div className={s.main}>
                    <div className={s.minPros}>
                        <a href="https://edu.gov.ru/"><img src={minpros} alt=""/>
                            <span>МИНИСТЕРСТВО ПРОСВЕЩЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</span></a>
                    </div>
                    <div className={s.buttons}>
                        <div className={s.searchButton}>
                            <SearchOutlined style={{paddingRight: "5px", fontSize: "40px"}}/>
                            <span>Поиск</span>
                        </div>
                        <div className={s.visionButton}>
                            <EyeOutlined style={{paddingRight: "5px", fontSize: "25px"}}/>
                            <span>Версия для слабовидящих</span>
                        </div>

                        {/*//Кнопка ТГ*/}
                        <div className={s.btn}><a href="https://t.me/dpoCZPID">

                            <svg width="40px" height="40px" viewBox="13 45 120 120" fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier"></g>
                                <g id="SVGRepo_tracerCarrier"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path
                                        d="M76.33 132.14L62.5 143.73L58.59 144.26L49.84 114.11L19.06 104L113.82 67.8799L118.29 67.9799L103.36 149.19L76.33 132.14ZM100.03 83.1399L56.61 109.17L61.61 130.5L62.98 130.19L68.2 113.73L102.9 83.4799L100.03 83.1399Z"
                                    ></path>
                                </g>
                            </svg>
                        </a></div>

                        {/*Кнопка ВК*/}
                        <div className={s.btn}><a href="https://vk.com/fcprcru">
                            <svg style={{paddingRight: "2px"}} fill="currentColor" width="40px" height="40px" viewBox="-2 0 30 30" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg">
                                <title>vk</title>
                                <path
                                    d="M16.563 15.75c-0.5-0.188-0.5-0.906-0.531-1.406-0.125-1.781 0.5-4.5-0.25-5.656-0.531-0.688-3.094-0.625-4.656-0.531-0.438 0.063-0.969 0.156-1.344 0.344s-0.75 0.5-0.75 0.781c0 0.406 0.938 0.344 1.281 0.875 0.375 0.563 0.375 1.781 0.375 2.781 0 1.156-0.188 2.688-0.656 2.75-0.719 0.031-1.125-0.688-1.5-1.219-0.75-1.031-1.5-2.313-2.063-3.563-0.281-0.656-0.438-1.375-0.844-1.656-0.625-0.438-1.75-0.469-2.844-0.438-1 0.031-2.438-0.094-2.719 0.5-0.219 0.656 0.25 1.281 0.5 1.813 1.281 2.781 2.656 5.219 4.344 7.531 1.563 2.156 3.031 3.875 5.906 4.781 0.813 0.25 4.375 0.969 5.094 0 0.25-0.375 0.188-1.219 0.313-1.844s0.281-1.25 0.875-1.281c0.5-0.031 0.781 0.406 1.094 0.719 0.344 0.344 0.625 0.625 0.875 0.938 0.594 0.594 1.219 1.406 1.969 1.719 1.031 0.438 2.625 0.313 4.125 0.25 1.219-0.031 2.094-0.281 2.188-1 0.063-0.563-0.563-1.375-0.938-1.844-0.938-1.156-1.375-1.5-2.438-2.563-0.469-0.469-1.063-0.969-1.063-1.531-0.031-0.344 0.25-0.656 0.5-1 1.094-1.625 2.188-2.781 3.188-4.469 0.281-0.5 0.938-1.656 0.688-2.219-0.281-0.625-1.844-0.438-2.813-0.438-1.25 0-2.875-0.094-3.188 0.156-0.594 0.406-0.844 1.063-1.125 1.688-0.625 1.438-1.469 2.906-2.344 4-0.313 0.375-0.906 1.156-1.25 1.031z"></path>
                            </svg>
                        </a></div>

                        {/*Кнопка с вопросом*/}
                        <div className={s.btn}><a href="/feedback">
                            ?
                        </a></div>
                    </div>
                </div>
            </div>
            <div className={s.middle}>
                <div className={s.main}>
                    <div className={s.icon}>
                        <NavLink to="/"><img src={icon} alt=""/></NavLink>
                    </div>
                    <div className={s.cabinet}>
                        {isAuthenticated ? <NavLink to="/cabinet">
                            Личный Кабинет
                            <img src={profile} alt=""/>
                        </NavLink> :
                            <NavLink to="/login">
                                Войти
                                <img src={profile} alt=""/>
                            </NavLink>}
                    </div>
                </div>
            </div>
            <div className={s.bottom}>
                <div className={s.main}>
                    <BottomUrl url="/dataeducation" title="Сведения об образовательной организации"/>
                    <BottomUrl url="/courses" title="Курсы"/>
                    <BottomUrl url="/calendar" title="Календарь"/>
                    <BottomUrl url="/teachers" title="Преподаватели"/>
                    <BottomUrl url="/library" title="Библиотека"/>
                    <BottomUrl url="/contacts" title="Контакты"/>
                </div>
            </div>
        </div>
    )
}
export default NewHeader

const BottomUrl = ({title, url}) => {
    const [urlColor, setUrlColor] = useState({color: "black"});
    const getUrl = window.location.pathname;

    useEffect(() => {
        if (getUrl.includes(url)) {
            setUrlColor({color: "#cb2d5f"});
        } else {
            setUrlColor({color: "black"});
        }
    }, [getUrl]);

    return (
        <div className={s.bottomUrl} >
            <NavLink style={urlColor} to={url}>{title}</NavLink>
        </div>
    )
}