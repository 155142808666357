import s from "../Modules/Modules.module.css";
import m from "./CourseEx.module.css";
import {CourseDescription, PageTitle, SubTitle} from "../Modules/Modules";
import bc from "../../assets/Imgs/BigCourse.png";
import {TeacherBlock} from "./CourseExample1";
import img1 from "../../assets/Imgs/med.jpg";
import {NavLink} from "react-router-dom";

const MediationCourseFree = () => {
    return (
        <div className={m.main}>
            <div className={m.coursePage}>
                <div className={m.courseLeft}>
                    <SubTitle title="Медиация и медиативные технологии"/>
                    <PageTitle
                        title="Введение в медиацию. Медиативный и восстановительный подходы. 72 академических часа"/>
                    <TeacherBlock name="Марина Викторовна Быкова"
                                  position='Аналитик отдела медиации ФГБУ "Центр защиты прав и интересов детей"'/>
                    {window.innerWidth < 976 ? (
                        <div className={m.courseRight}>
                            <p></p>
                            <BuyWindow/>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className={m.courseImg}>
                        <img src={img1} alt=""/>
                    </div>
                    <CourseDescription title="Описание">
                        <CourseDesc/>
                    </CourseDescription>
                    {/*<CourseDescription title="Раздел 1.">*/}
                    {/*    <Razdel/>*/}
                    {/*</CourseDescription>*/}
                    {/*<CourseDescription title="Раздел 2.">*/}
                    {/*</CourseDescription>*/}
                    {/*<CourseDescription title="Календарь">*/}
                    {/*    <CalendarExample/>*/}
                    {/*</CourseDescription>*/}
                </div>
                {window.innerWidth >= 976 ? (
                    <div className={m.courseRight}>
                        <BuyWindow/>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
export default MediationCourseFree;

const CourseDesc = () => {
    return (
        <div className={s.courseDescription}>
            Программа направлена на решение задач в области актуальной социальной
            проблематики – профилактики и коррекции девиантного поведения обучающихся
            за счет интеграции механизмов медиации и восстановительного подхода в
            образовательную и воспитательную работу специальных учебно-воспитательных
            учреждений. К освоению программы допускаются лица, имеющие среднее
            профессиональное и (или) высшее образование; лица, получающие высшее
            образование. Программа предназначена для повышения квалификации работников
            специальных учебно-воспитательных учреждений открытого или закрытого типа.
            Все занятия программы проводятся преподавателями лично, формат занятий –
            интерактивный, практикоориентированный.
            <p></p>
            <p style={{fontSize: "24px"}}><b>Сроки проведения:</b> с 23 сентября 2024 г. по 28 ноября 2024 г</p>
            <strong>Цель программы:</strong>
            <br style={{fontSize: "24px"}}/>
            Совершенствование (получение) новой компетенции, необходимой для
            профессиональной деятельности, и (или) повышение профессионального уровня
            в рамках имеющейся квалификации. В результате освоения программы у
            слушателей будут знания о современной государственной политике РФ в
            области образования, правовых основах применения
            медиативно-восстановительных технологий в образовательной организации,
            принципах построения конструктивных взаимоотношений со всеми участниками
            образовательных отношений; умения и навыки применения медиации и
            восстановительного подхода в образовательной системе.
            <p></p>
            <strong> Программа курса:</strong>
            <br style={{fontSize: "24px"}}/>
            <ul>
                <li>
                    <b>Модуль 1.</b> «Введение в медиацию».{" "}
                </li>
                <li>
                    <b>Модуль 2.</b> «Медиативный подход».
                </li>
                <li>
                    <b>Модуль 3.</b> «Основы восстановительного подхода».
                </li>
                <li>
                    В рамках курса предусмотрены зачеты и итоговая аттестация в виде
                    экзамена.
                </li>
            </ul>
            <p></p>
            <p></p>
            <b>Длительность:</b> 13 занятий
            <p></p>
            <b>Учебная нагрузка по курсу:</b> 4 ак. часа в день.
            <p></p>
            <strong>Даты проведения занятий (понедельник, среда):</strong><br style={{fontSize: "24px"}}/>
            <strong>Сентябрь:</strong> 23, 25, 30
            <br/>
            <strong>Октябрь:</strong> 2, 7, 9, 14, 16, 21, 23, 28, 30
            <br/>
            <strong>Ноябрь:</strong> 6
            <p></p>
            <b>Время занятий:</b> 15:00 – 18:10
            <p></p>
        </div>
    );
};

// const Razdel = () => {
//     return <div className={s.courseDescription}>
//         <strong>Правовые основы и направления деятельности органов опеки и попечительства при рассмотрении споров о
//             воспитании
//             детей</strong>
//         <p></p>
//         <div className={s.ref}>
//             <a href="/course1/part1"><strong>Подраздел 1.1</strong><br/>
//                 Правовые основания и направления деятельности органов опеки и попечительства при рассмотрении споров о
//                 воспитании детей (на досудебной стадии, в судебном процессе, в исполнительном производстве)</a>
//         </div>
//         <p></p>
//         <strong>Подраздел 1.2</strong><br/>
//         Учет мнения ребенка при решении вопросов, затрагивающих его права и интересы. Методы и способы выяснения мнения
//         ребенка в случаях, предусмотренных законодательством. Учет психологических, медицинских, социальных факторов при
//         общении с детьми разных возрастных групп при выявлении их мнения
//     </div>
// }
//
// const Zadanie = ({
//                      questions
//                  }) => {
//     return (
//         <div className={s.courseDescription}>
//             {questions.map(el =>
//                 <Question id={el.id} question={el.question} answer={el.answer}/>
//             )}
//             <div className={s.send}>
//                 <a href="/">Отправить ответы</a>
//             </div>
//
//         </div>
//     )
// }
//
// const Question = ({
//                       id, question, answer = null
//                   }) => {
//     return (
//         <div>
//             <div style={{paddingBottom: "20px"}}>
//                 <strong>Вопрос {id}</strong>
//             </div>
//             <div style={{paddingBottom: "20px"}}>
//                 {question}
//             </div>
//             <div className={s.qInput}>
//                 <input style={answer === null ? {backgroundColor: "white"} : {backgroundColor: "#008E4C17"}}
//                        id="questionInput" type="text" placeholder={answer === null ? "Ответ" : answer}/>
//                 <button id="searchButton"
//                         style={answer === null ? {backgroundColor: "#e07b9b"} : {backgroundColor: "#3F5070"}}>{answer === null ? "Ответить" : "Ответ принят"}</button>
//             </div>
//
//         </div>
//     )
// }

const BuyWindow = () => {
    return (
        <div className={m.buy}>
            <div className={m.info}>
                <div style={{fontSize: "18px", paddingBottom: "5px"}}>
                    <strong>Принять участие</strong>
                </div>
                {/*<div style={{fontSize: "16px", paddingBottom: "10px"}}>*/}
                {/*    <span><strong>0 человек</strong></span> прошли курс*/}
                {/*</div>*/}
                <div
                    style={{fontSize: "12px", paddingBottom: "30px", color: "#808080"}}
                >
                    72 академических часа
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingBottom: "10px",
                    }}
                >

                </div>
                <div className={m.buyButton}>
                    <NavLink to="/registration-free">Записаться на курс</NavLink>
                </div>
            </div>
        </div>
    );
};
